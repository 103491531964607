import { useEffect, useState } from 'react';

import domready from 'domready';
import Reveal from 'reveal.js';

function useReveal(history) {
  const [loading, setLoadingState] = useState(true);
  useEffect(() => {
    domready(() => {
      Reveal.initialize({
        controls: false,
        progress: false,
        touch: true,
        history: true,
        previewLinks: true,
        center: false,
        width: 1920,
        height: 1080,
        margin: 0,
        mouseWheel: false,
        transition: 'fade', // none/fade/slide/convex/concave/zoom
      });
    });

    const listener = Reveal.addEventListener('ready', () => {
      // event.currentSlide, event.indexh, event.indexv
      setLoadingState(false);
      window.onresize = function () {
        document.body.height = window.innerHeight;
      };
      window.onresize();
    });

    return () => {
      Reveal.removeEventListener('ready', listener)
      setLoadingState(false);
    }
  }, [history]);
  return {
    loading,
  };
}

export default useReveal;
