import React from "react";

function IconNext ({
	backgroundColor = '#FFFFFF'
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="26"
      viewBox="0 0 16 26"
    >
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
      >
        <g stroke="#EC1B2E" strokeWidth="5" transform="translate(-51 -661)">
          <g transform="translate(30 644)">
            <path d="M34.1201172 20L24.1201172 30 34.1201172 40"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default IconNext;
