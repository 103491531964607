import React, { useRef, useState } from 'react'
// import SwiperCore, { Virtual } from 'swiper'
import { Swiper } from 'swiper/react'
import cn from 'classnames'

import IconNext from './IconNext'
import IconPrev from './IconPrev'

import style from './styles.module.scss'

import 'swiper/swiper.scss'

// SwiperCore.use([Virtual])

const SwiperCampaigns = ({
    children,
    history
}) => {
    const swiperRef = useRef(null)
    const [onReachEnd, setReachEnd] = useState(false)

    const goNext = () => {
        if (!onReachEnd && swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext()
        }
    }

    const goPrev = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev()
        }
    }

    React.useEffect(() => {
        if (swiperRef && swiperRef.current) {
            setTimeout(() => { // slider workaround
                // update swiper internally
                swiperRef.current.swiper.update()
                // go to first slide
                swiperRef.current.swiper.slideTo(0, 0)
            }, 0)
        }
    }, [swiperRef, history.location.hash])

    return (
        <>
            <Swiper
                spaceBetween={60}
                slidesPerView={2.6}
                onProgress={progress => {
                    setReachEnd(progress.isEnd)
                }}
                ref={swiperRef}
                // virtual
            >
                {children}
            </Swiper>
            <div
                className={cn(style.btn, style.btnLeft)}
                onClick={goPrev}>
                <IconPrev backgroundColor={'#EC1B2E'} />
            </div>
            <div
                className={cn(style.btn, style.btnNext, {
                    [style.disable]: onReachEnd
                })}
                onClick={goNext}>
                <IconNext backgroundColor={'#EC1B2E'} />
            </div>
        </>
    )
}

export default SwiperCampaigns
