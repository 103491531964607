import React from 'react'
import useReveal from 'hooks/useReveal'

// import Navigation from './Navigation'
// import LanguageSwitch from './LanguageSwitch'

import 'reveal.js/css/reveal.css'
import 'reveal.js/css/theme/black.css'
import './styles.css'

const Reveal = ({ children, history }) => {
  const { loading } = useReveal(history)
  return (
    <div className='reveal'>
      <div className='slides'>
        {loading && <div>Loading...</div>}
        {children}
        {/* <Navigation /> */}
      </div>
      {/*<LanguageSwitch history={history} lang={lang === 'fr' ? 'en' : 'fr'}/>*/}
    </div>
  )
}

export default Reveal
