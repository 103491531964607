import React from 'react'
import { withRouter } from 'react-router-dom'
import cn from 'classnames'
import queryString from 'query-string'

import styles from './styles.module.scss'

import FLAG_CAD from './images/FLAG_CA.jpg'
import FLAG_US from './images/FLAG_US.jpg'

const CountrySelection = ({ history }) => {
    const { hash, pathname, search } = history.location
    const queries = queryString.parse(search)

    const goCA = React.useCallback(() => {
        queries.country = 'CA'
        history.push(`${pathname}?${queryString.stringify(queries)}$${hash}`)
    }, [queries, history, pathname, hash])

    const goEU = React.useCallback(() => {
        queries.country = 'US'
        history.push(`${pathname}?${queryString.stringify(queries)}${hash}`)
    }, [queries, history, pathname, hash])

    let caActive = null
    let usActive = null

    if (queries.country === 'US') {
        usActive = true
    } else {
        caActive = true
    }

    return (
        <div className={styles.countrySelection}>
            <div
                className={cn(styles.btn, {
                    [styles.btnActive]: caActive
                })}
                onClick={goCA}
            >
                <span
                    style={{
                        backgroundImage: `url(${FLAG_CAD})`
                    }}
                />
            </div>
            <div
                className={cn(styles.btn, {
                    [styles.btnActive]: usActive
                })}
                onClick={goEU}
            >
                <span
                    style={{
                        backgroundImage: `url(${FLAG_US})`
                    }}
                />
            </div>
        </div>
    )
}

export default withRouter(CountrySelection)
