import React from 'react'

import styles from './styles.module.scss'

export default function Card ({
    href,
    previewLink,
    style,
    src,
    alt
}) {
    return (
        <a
            href={href}
            className={styles.card}
            target='_blank'
            data-preview-link={previewLink}
            rel="noopener noreferrer"
            style={style}>
            <img src={src} alt={alt} />
        </a>
    )
}
