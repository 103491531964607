import React from 'react'

import style from './styles.module.scss'

const Slide = ({ backgroundUrl, children }) => (
  <section className={style.slide} style={{ backgroundImage: `url(${backgroundUrl})` }}>
    {children}
  </section>
)

export default Slide
