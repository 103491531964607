import React from 'react'
import RevealJs from 'reveal.js'
import queryString from 'query-string'

import Reveal from 'components/Reveal'
import Slide from 'components/Slide'
import Button from 'components/Button'
import CountrySelection from 'components/CountrySelection'
import SwiperCampaigns from 'components/SwiperCampaigns'
import { SwiperSlide } from 'swiper/react'

import Card from 'components/Card'

import alumniGift20203 from './images/campaigns/alumni-gift-2020-3.jpg'
import alumniGift20202 from './images/campaigns/alumni-gift-2020-2.jpg'
import alumniGift20201 from './images/campaigns/alumni-gift-2020-1.jpg'
import commingSoon from './images/campaigns/comming_soon.jpg'

import SLIDE1 from './images/slides/01.jpg'
import SLIDE2 from './images/slides/02.jpg'
import SLIDE3 from './images/slides/03.jpg'
import SLIDE4 from './images/slides/04.jpg'
import SLIDE5 from './images/slides/05.jpg'
import SLIDE6 from './images/slides/06.jpg'
import SLIDE7 from './images/slides/07.jpg'
import SLIDE8 from './images/slides/08.jpg'
import SLIDE9 from './images/slides/09.jpg'
import SLIDE10_CA from './images/slides/10_CA.jpg'
import SLIDE10_US from './images/slides/10_US.jpg'
import SLIDE11_CA from './images/slides/11_CA.jpg'
import SLIDE11_US from './images/slides/11_US.jpg'
import SLIDE12 from './images/slides/12.jpg'

// import styles from './styles.module.scss'

const Home = ({
    match,
    history
}) => {
    let { id } = match.params
    const { search } = history.location
    const { country } = queryString.parse(search)
    const [presentation, setPresentation] = React.useState(null)
    const isUS =  country === 'US'

    React.useEffect(() => {
        (async () => {
            const response = await fetch(`https://${process.env.REACT_APP_API_HOST}/api/presentation/${id}.json`)

            if (!response.ok) {
                console.log('Unable to load presentation')
            }

            const json = await response.json()
            setPresentation(json.presentation)
            RevealJs.configure({ previewLinks: false }) // make reveal.js refresh the previewlink
        })()
    }, [id])

  return (
    <Reveal history={history}>
      <Slide backgroundUrl={SLIDE1} />
      <Slide backgroundUrl={SLIDE2} />
      <Slide backgroundUrl={SLIDE3} />
      <Slide backgroundUrl={SLIDE4} />
      <Slide backgroundUrl={SLIDE5} />
      <Slide backgroundUrl={SLIDE6} />
      <Slide backgroundUrl={SLIDE7}>
              <div style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', paddingTop: '20%' }}>
                    <SwiperCampaigns history={history}>
                        <SwiperSlide style={{ maxWidth: '90px' }} />

                        <SwiperSlide>
                            <Card
                                src={alumniGift20202}
                                previewLink
                                href={presentation ? presentation.livePreview['non_profit-non_profit_2021_2'] : '#missing'}
                            />
                        </SwiperSlide>

                        <SwiperSlide>
                            <Card
                                src={alumniGift20203}
                                previewLink
                                href={presentation ? presentation.livePreview['non_profit-non_profit_2021_3'] : '#missing'}
                            />
                        </SwiperSlide>

                        <SwiperSlide>
                            <Card
                                src={alumniGift20201}
                                previewLink
                                href={presentation ? presentation.livePreview['non_profit-non_profit_2021_1'] : '#missing'}
                            />
                        </SwiperSlide>

                        <SwiperSlide>
                            <Card
                                src={commingSoon}
                                href={'#commingsoon'}

                            />
                        </SwiperSlide>
                    </SwiperCampaigns>
                </div>
      </Slide>
      <Slide backgroundUrl={SLIDE8} />
      <Slide backgroundUrl={SLIDE9} >
          <Button style={{
            position: 'absolute',
            left: '4.263em',
            bottom: '3.6915em',
          }}
          previewLink
          href={presentation ? presentation.livePreview.crm : 'https://sandbox.shiftone.io/login'}
          >
            View Shift One
          </Button>
      </Slide>
      <Slide backgroundUrl={isUS ? SLIDE10_US : SLIDE10_CA}>
        <CountrySelection />
      </Slide>
      <Slide backgroundUrl={isUS ? SLIDE11_US : SLIDE11_CA}>
        <CountrySelection />
      </Slide>
      <Slide backgroundUrl={SLIDE12} />
    </Reveal>
  )
}

export default Home
