import React from 'react'
import cn from 'classnames'

import styles from './styles.module.scss'

export default function Button ({
  children,
  href,
  previewLink,
  small,
  onClick,
  style
}) {
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
      href={onClick ? null : href}
      onClick={onClick}
      className={cn(styles.button, {
        [styles.small]: small
      })}
      data-preview-link={onClick ? null : previewLink}
      style={style}>
      {children}
    </a>
  )
}
